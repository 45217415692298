article.media {
  flex-wrap: wrap;
  justify-content: center;
}
@media screen and (min-width: 768px) {
  article.media {
    justify-content: initial;
    flex-wrap: initial;
  }
}
