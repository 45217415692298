@import 'bulma/sass/utilities/derived-variables';
.arrow {
  color: $primary-dark;
  &:hover {
    color: white;
  }
}
.hero.has-background {
  position: relative;
  overflow: hidden;
}
.hero-background {
  position: absolute;
  object-fit: cover;
  object-position: center center;
  width: 100%;
  height: 100%;
}
.hero-background.is-transparent {
  opacity: 0.7;
}
@media screen and (min-width: 768px) {
  .hero-background {
    object-fit: contain;
  }
}
